import React from "react";
import Seo from "~/components/Seo";
import LearnMore from "~/features/marketing/learn-more";
import schoolsBackgroundImage from "~/images/schools-background.jpg";
import gameOverviewDocument from "~/images/schools-districts-game-overview.pdf";

function SchoolsLearnMorePage() {
  return (
    <LearnMore
      headline2="ProblemScape: Value of Xperts contains all the resources students need to make up for learning loss and relearn foundational algebra concepts!"
      headlineButtons={[
        {
          to: "/contact/?source=schools-learn-more-request-demo",
          label: "Request a Demo",
        },
        {
          to: "/contact/?source=schools-learn-more-request-demo",
          label: "Interested in Piloting",
        },
        {
          to: gameOverviewDocument,
          label: "Game Overview",
          forceExternal: true,
        },
      ]}
      featuresBackgroundImage={schoolsBackgroundImage}
      features={[
        "An adventure game with real-world problems set in a virtual world to understand the applications of what they are learning",
        "Videos to explain concepts they’re yet to learn, and multi-level practice sessions to practice what they have learned",
        "Eight chapters that cover all of the algebra standards for sixth grade",
        "Address SEL competencies to help students overcome math-anxiety and and develop self-efficacy",
      ]}
      theme="red"
    />
  );
}

export function Head() {
  return <Seo title="Schools - Learn More" />;
}

export default SchoolsLearnMorePage;
